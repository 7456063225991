/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                setTimeout(function () {
                    $('body, html').scrollTop(0);
                    $('.load-overlay').velocity('fadeOut');
                }, 100);

                // JavaScript to be fired on the home page
                if ($(".toggle .toggle-title").hasClass('active')) {
                    $(".toggle .toggle-title.active").closest('.toggle').find('.toggle-inner').show();
                }

                $(".toggle .toggle-title").click(function () {
                    if ($(this).hasClass('active')) {
                        $(this).removeClass("active").closest('.toggle').find('.toggle-inner').slideUp(300);
                    } else {
                        $(this).addClass("active").closest('.toggle').find('.toggle-inner').slideDown(300);
                    }
                });

                $('.home .related article .item-content').matchHeight();
                $('.home .related article .tags').matchHeight();
                $('.best_cats .cat_row .item').matchHeight();
                $('section .partners .item p').matchHeight();

                $("#nav-toggle").click(function () {
                    $(this).toggleClass('open');
                    if ($('.navbar-main').hasClass('active')) {
                        $('.navbar-main').removeClass("active").slideUp(300);
                    } else {
                        $('.navbar-main').addClass("active").slideDown(300);
                    }
                });

                $(".filter_header").click(function () {
                    $('.filter_header .filter-trigger').toggleClass('open');
                    if ($('.providers .filter ul').hasClass('active')) {
                        $('.providers .filter ul').removeClass("active").slideUp(300);
                    } else {
                        $('.providers .filter ul').addClass("active").slideDown(300);
                    }
                });

                new Clipboard('.discount');
                $(".discount").click(function () {
                    $(this).find('.tooltip').show().delay(1000).fadeOut();
                });

                function sizes() {
                    var t = 0;
                    $(".providers .provider .provider_name").each(function () {
                        div = $(this);
                        div.css('height', 'auto');
                        if (div.outerHeight() > t) {
                            t = div.outerHeight();
                        }
                    });
                    $(".providers .provider .provider_name").each(function () {
                        $(this).css('height', t);
                    });
                }

                sizes();
                $(window).resize(function () {
                    sizes();
                });

                /* $(window).scroll(function () {
                    var scroll = $(window).scrollTop();
                    if (scroll >= 48) {
                        $(".navbar").addClass("scroll", 1000);
                    }
                    if (scroll < 48) {
                        $(".navbar").removeClass("scroll", 1000);
                    }

                    if ($('.entry-header .cta-top').length > 0) {
                        if (scroll >= $('.entry-header .cta-top').offset().top) {
                            $(".scroll_cta").show();
                        } else {
                            $(".scroll_cta").hide();
                        }
                    }
                }); */

                // Modal onload site
                $('#close_modal_onload').click(function (e) {
                    e.preventDefault();
                    $('.discount_modal_onload').velocity('fadeOut');
                });

                setTimeout(function () {
                    if (localStorage.getItem('onload_modal') !== 'opened') {
                        localStorage.setItem('onload_modal', 'opened');
                        $('.discount_modal_onload').velocity('fadeIn');
                        countDown();
                    }
                }, 5000);

                // Modal on close site
                $('#close_modal').click(function (e) {
                    e.preventDefault();
                    $('.discount_modal').velocity('fadeOut');
                });

                var triggered = false;
                var inWindow = false;
                $(window).on("mousemove", function (e) {
                    if (e.pageY > 100) {
                        inWindow = true;
                    }
                    if (e.pageY - $(window).scrollTop() < 5 && !triggered && localStorage.getItem('modal') !== 'opened' && inWindow === true) {
                        triggered = true;
                        if ($(this).outerWidth() > 760) {
                            localStorage.setItem('modal', 'opened');
                            $('.discount_modal').velocity('fadeIn');
                            countDown();
                        }
                    }
                });

                function countDown() {

                    var countDownDate = new Date();
                    countDownDate.setMinutes(countDownDate.getMinutes() + 10);
                    countDownDate.getTime();

                    // Update the count down every 1 second
                    var x = setInterval(function () {

                        // Get todays date and time
                        var now = new Date().getTime();

                        // Find the distance between now an the count down date
                        var distance = countDownDate - now;

                        // Time calculations for days, hours, minutes and seconds
                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                        var hours = ("0" + hours).slice(-2);
                        var minutes = ("0" + minutes).slice(-2);
                        var seconds = ("0" + seconds).slice(-2);


                        html = '<span class="minutes timer">' + minutes + "</span><span class='dots'>:</span>";
                        html += '<span class="seconds timer">' + seconds + "</span><span class='label'>sec</span>";

                        document.getElementById("countdown").innerHTML = html;

                        // If the count down is finished, write some text
                        if (distance < 0) {
                            clearInterval(x);
                            document.getElementById("countdown").innerHTML = "0";
                        }
                    }, 1000);
                }

                $( ".comment-list-showmore" ).click(function() {
                    $(this).hide();
                    $( ".comment-list-limiter-gradient" ).hide();
                    $( ".comment-list-limiter" ).removeClass( "comment-list-limiter" );
                });

                //scroller hero
                $('.review-cta a').on('click', function (e) {
                    e.preventDefault();
                    $('html, body').animate({
                        scrollTop: $("#reply").offset().top - $('.navbar').height() - 25
                    }, 1000);
                });

                function generate(){
                    var size = document.form.size.value;
                    var name = document.form.name.value;
                    var theme = document.form.theme.value;
                    var iframe_url = document.form.url.value;

                    // get size
                    var hig = '';
                    var wit = '';
                    switch (size) {
                        case 'small':
                            hig = '50';
                            wit = '600';
                            break;
                        case 'medium':
                            wit = '265';
                            hig = '115';
                            break;
                        case 'large':
                            hig = '200';
                            wit = '250';
                            break;
                    }

                    // parameters
                    var parm_slug = '?slug=' + name;
                    var parm_size = '&size=' + size;
                    var parm_theme = '&theme=' + theme;

                    // generate url
                    var url = iframe_url;
                    url += parm_slug;
                    url += parm_size;
                    url += parm_theme;

                    document.form.genratecode.value='<iframe name="review '+name+'" scrolling="no" frameBorder="0" src="'+url+'" height="'+hig+'" width="'+wit+'"></iframe>';
                }

                function preview() {
                    var ifrm = document.getElementById('Iframe');
                    ifrm = ifrm.contentWindow || ifrm.contentDocument.document || ifrm.contentDocument;
                    ifrm.document.open();
                    ifrm.document.write(document.form.genratecode.value);
                    ifrm.document.close();
                }

                $('#generate-code').click(function() {
                    generate();
                    preview();

                    $('.iframe-preview').show();
                    $('#select-code').show();

                    $('html, body').animate({
                        scrollTop: $("#preview").offset().top - $('.navbar').height() - 25
                    }, 1000);
                });

                $('#select-code').click(function(e) {
                    e.preventDefault();

                    $('html, body').animate({
                        scrollTop: $("#genratecode").offset().top - $('.navbar').height() - 25
                    }, 1000);
 
                    $('#genratecode').select();
                    $('#genratecode').focus();
                });

                setTimeout(function(){
                    $('.sticky_message').velocity('fadeIn');
                }, 8000);

                 $('.sticky_message').click(function(){
                     $('.sticky_message').velocity('fadeOut');
                 });

                 if (window.matchMedia("(max-width: 990px)").matches) {
                   /* the viewport is less than 768 pixels wide */
                   $('.navbar-main .usps').slick({
                     dots: false,
                     arrows: false,
                     infinite: false,
                     speed: 300,
                     slidesToShow: 1,
                     slidesToScroll: 1,
                     autoplay: true,
                     autoplaySpeed: 3000
                     //variableWidth: true,
                   });

                   setTimeout(function(){
                       $('.users_online').addClass('show');
                   }, 2000);
                 } 

                 $('.more_info_button').click(function(){
                     $(this).parent().find('.information').toggle();
                 });
                 
                 $('.hero .first_item .button').click(function(){
                     setTimeout(function(){
                         $('.related_partner.first_item').show();
                     }, 1500);
                 });

                 $('.hero .second_item .button').click(function(){
                     setTimeout(function(){
                         $('.related_partner.first_item').show();
                     }, 1500);
                 });

                 $('.related_partner .close').click(function(){
                     $('.related_partner').hide();
                 });

                 setTimeout(function(){
                     $('.partner_single_modal').show();

                     $('.partner_single_modal .voordelen ul').slick({
                       dots: false,
                       arrows: false,
                       infinite: false,
                       speed: 300,
                       slidesToShow: 1,
                       slidesToScroll: 1,
                       autoplay: true,
                       autoplaySpeed: 3000
                       //variableWidth: true,
                     });
                 }, 8000);

                 $('.open_text').click(function(){
                     $(this).toggleClass('active');
                     $(this).parent().find('.hidden_text').toggle();
                 });

                 $(".testimonial-reel").slick({
                   centerMode: true,
                   centerPadding: "40px",
                   dots: true,
                   slidesToShow: 3,
                   infinite: true,
                   arrows: false,
                   lazyLoad: "ondemand",
                   responsive: [
                     {
                       breakpoint: 1024,
                       settings: {
                         slidesToShow: 2,
                         centerMode: false
                       }
                     },
                     {
                       breakpoint: 767,
                       settings: {
                         slidesToShow: 1,
                         centerPadding: "5px",
                       }
                     }
                   ]
                 });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired


                if ($(window).width() > 991) {
                    $(".col-md-8.main, .col-md-4.aside").stick_in_parent();
                }

            }
        },
        'faqs': {
            init: function () {

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'blog': {
            init: function () {
                function sizes() {
                    $(".main .has-post-thumbnail").each(function () {
                        div = $(this).find('.entry-summary');
                        $(this).css("height", div.outerHeight(true));
                    });
                }

                sizes();
                $(window).resize(function () {
                    sizes();
                });
            }
        },
        'tag': {
            init: function () {
                function sizes() {
                    $(".main .has-post-thumbnail").each(function () {
                        div = $(this).find('.entry-summary');
                        $(this).css("height", div.outerHeight(true));
                    });
                }

                sizes();
                $(window).resize(function () {
                    sizes();
                });
            }
        },
        'search': {
            init: function () {
                function sizes() {
                    $(".main .has-post-thumbnail").each(function () {
                        div = $(this).find('.entry-summary');
                        $(this).css("height", div.outerHeight(true));
                    });
                }

                sizes();
                $(window).resize(function () {
                    sizes();
                });
            }
        },
        'home': {
            init: function () {

            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
/* jshint ignore:end */